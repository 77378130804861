<template>
  <my-form
    v-model="form"
    title="Termo de Abertura de Projeto"
    resource="projeto-pesquisa/acompanhamento/editar-tap"
    fill-header-with-actions
    @validate="errors = $event"
    :expandable="closed"
    :hide-default-actions="closed"
    @success="$emit('success')"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <my-select
            v-model="form.strategic_pillar"
            clearable
            :disabled="closed"
            :error-messages="errors.strategic_pillar"
            label="Pilar Estratégico"
            required
            resource="projeto-pesquisa/pilares?parent_id=null"
            return-object
          />
        </v-col>
        <v-col cols="12" md="3">
          <my-select
            v-model="form.strategic_group"
            clearable
            :disabled="closed"
            :error-messages="errors.strategic_group"
            label="Grupo Estratégico"
            required
            :resource="form.strategic_pillar ? `projeto-pesquisa/pilares?parent_id=${form.strategic_pillar.id}` : null"
            return-object
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
            v-model="form.technical_justification"
            :disabled="closed"
            :error-messages="errors.technical_justification"
            label="Justificativa Técnica"
            required
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
            v-model="form.commercial_justification"
            :disabled="closed"
            :error-messages="errors.commercial_justification"
            label="Justificativa Comercial"
            required
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
            v-model="form.additional_information"
            :disabled="closed"
            :error-messages="errors.additional_information"
            label="Metodologia, custos e prazos"
            required
          />
        </v-col>
        <v-col cols="12">
          <pesquisa-acompanhamento-cronograma-execucao-form :disabled="closed"/>
        </v-col>
      </v-row>
    </v-card-text>
    <custom-snackbar ref="snackbar" />
  </my-form>
</template>
<script>
import MyForm from "@/view/components/MyForm.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MySelect from "@/view/components/MySelect.vue";
import PesquisaAcompanhamentoCronogramaExecucaoForm
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/CronogramaExecucaoForm.vue";

export default {
  name: 'PesquisaAcompanhamentoTapForm',

  components: {PesquisaAcompanhamentoCronogramaExecucaoForm, MySelect, MyTextArea, MyTextField, MyForm},

  props: {
    closed: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    form: {
      strategic_pillar: null,
      strategic_group: null,
      technical_justification: null,
      commercial_justification: null,
      additional_information: null,
    },
    errors: [],
  })
}
</script>