<template>
  <div>
    <v-timeline
      align-top
      dense
    >
      <v-timeline-item
          v-if="step>=5"
          fill-dot
          small
      >
        <pesquisa-acompanhamento-etapa-execucao-form />
      </v-timeline-item>
      <v-timeline-item
          v-if="step>=4"
          fill-dot
          small
      >
        <pesquisa-acompanhamento-aprovacao-tap-form
            :closed="step>=5"
            @success="getStep"
        />
      </v-timeline-item>
      <v-timeline-item
          v-if="step>=2"
          fill-dot
          small
      >
        <pesquisa-acompanhamento-tap-form
            :closed="step>=5"
            @success="getStep"
        />
      </v-timeline-item>
      <v-timeline-item
          v-if="step>=1"
          fill-dot
          small
      >
        <pesquisa-acompanhamento-aprovacao-tsp-form
            :closed="step>=2"
            @success="getStep"
        />
      </v-timeline-item>
      <v-timeline-item
          v-if="step>=1"
          fill-dot
          small
      >
        <pesquisa-acompanhamento-tsp-form />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MyForm from "@/view/components/MyForm.vue";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import PesquisaAcompanhamentoTspForm from "@/view/pages/projeto-pesquisa/acompanhamento/Components/TspForm.vue";
import PesquisaAcompanhamentoAprovacaoTspForm
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/AprovacaoTspForm.vue";
import PesquisaAcompanhamentoAprovacaoTapForm
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/AprovacaoTapForm.vue";
import PesquisaAcompanhamentoTapForm from "@/view/pages/projeto-pesquisa/acompanhamento/Components/TapForm.vue";
import PesquisaAcompanhamentoEtapaExecucaoForm
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/EtapaExecucaoForm.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: 'PesquisaSolicitacaoProjetoCreateEdit',

  components: {
    PesquisaAcompanhamentoEtapaExecucaoForm,
    PesquisaAcompanhamentoTapForm,
    PesquisaAcompanhamentoAprovacaoTapForm,
    PesquisaAcompanhamentoAprovacaoTspForm, PesquisaAcompanhamentoTspForm, MyForm, MyTextArea, MyTextField},

  props: {
    resource: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    step: 1,
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Pesquisa'},
      {title: 'Acompanhamento do Projeto'},
    ])

    this.getStep()
  },

  methods: {
    async getStep() {
      const step = (await ApiService.get(`projeto-pesquisa/acompanhamento/follow-up/${this.$route.params.id}/step`)).data

      if (step === -1) {
        this.$router.push({path: '/projeto-pesquisa/acompanhamento'})
      } else {
        this.step = step
      }
    },
  },
}
</script>