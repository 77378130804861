<template>
  <my-list-card
    :default-item="defaultItem"
    editable
    fill-header
    :headers="headers"
    headerColor="white"
    hide-add-action
    disable-pagination
    :params="{ research_project_id: $route.params.id }"
    ref="list"
    resource="projeto-pesquisa/acompanhamento/etapas-exec-tap"
    title="Acompanhamento de Execução do Projeto"
  >
    <template #item.description="{ item }">
      <my-text-field
        v-if="item.editing && item.id === 0"
        v-model="item.description"
        :error-messages="item.errors && item.errors.description ? item.errors.description : []"
      />
      <span v-else>{{ item.description }}</span>
    </template>
    <template #item.forecast_start_at="{ item }">
      <my-text-field
          v-if="item.editing && item.id === 0"
          v-model="item.forecast_start_at"
          :error-messages="item.errors && item.errors.forecast_start_at ? item.errors.forecast_start_at : []"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="date"
      />
      <span v-else>{{ item.forecast_start_at | date }}</span>
    </template>
    <template #item.forecast_end_at="{ item }">
      <my-text-field
          v-if="item.editing && item.id === 0"
          v-model="item.forecast_end_at"
          :error-messages="item.errors && item.errors.forecast_end_at ? item.errors.forecast_end_at : []"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="date"
      />
      <span v-else>{{ item.forecast_end_at | date }}</span>
    </template>
    <template #item.budget_value="{ item }">
      <my-currency
          v-if="item.editing && item.id === 0"
          v-model="item.budget_value"
          clearable
          :error-messages="item.errors && item.errors.budget_value ? item.errors.budget_value : []"
          prefix="R$"
      />
      <v-row v-else dense no-gutters>
        <v-col cols="auto">
          <span>R$</span>
        </v-col>
        <v-col class="text-right">
          <span>{{ item.budget_value | formatNumber }}</span>
        </v-col>
      </v-row>
    </template>
    <template #item.started_at="{ item }">
      <my-text-field
        v-if="item.editing && item.id !== 0"
        v-model="item.started_at"
        :error-messages="item.errors && item.errors.started_at ? item.errors.started_at : []"
        mask="##/##/####"
        placeholder="dd/mm/aaaa"
        type="date"
      />
      <span v-else>{{ item.started_at | date }}</span>
    </template>
    <template #item.ended_at="{ item }">
      <my-text-field
        v-if="item.editing && item.id !== 0"
        v-model="item.ended_at"
        :error-messages="item.errors && item.errors.ended_at ? item.errors.ended_at : []"
        mask="##/##/####"
        placeholder="dd/mm/aaaa"
        type="date"
      />
      <span v-else>{{ item.ended_at | date }}</span>
    </template>
    <template #item.actual_value="{ item }">
      <my-currency
        v-if="item.editing && item.id !== 0"
        v-model="item.actual_value"
        :error-messages="item.errors && item.errors.actual_value ? item.errors.actual_value : []"
        prefix="R$"
      />
      <v-row v-else-if="item.actual_value" dense no-gutters>
        <v-col cols="auto">
          <span>R$</span>
        </v-col>
        <v-col class="text-right">
          <span>{{ item.actual_value | formatNumber }}</span>
        </v-col>
      </v-row>
    </template>

    <template #item.saving="{ item }">
      <v-row v-if="item.actual_value" dense no-gutters>
        <v-col cols="auto">
          <span class="font-weight-medium" :class="`${getSavingValue(item).color}--text`">R$</span>
        </v-col>
        <v-col class="text-right font-weight-medium" :class="`${getSavingValue(item).color}--text`">
          <span>{{ getSavingValue(item).value | formatNumber }}</span>
        </v-col>
      </v-row>
    </template>
    <template #item.deadline="{ item }">
      <v-chip label :color="deadline(item).color" dark small>
        <span class="font-weight-medium">
          {{ deadline(item).text }}
        </span>
      </v-chip>
    </template>
    <template #item.action="{ item }">
      <v-tooltip left>
        <template #activator="{ on: onTootip }">
          <v-icon
            class="mr-1"
            icon
            @click="!item.editing ? $refs.list.editItem(item) : $refs.list.save(item)"
            v-on="onTootip"
          >
            {{ !item.editing ? 'mdi-pencil-outline' : 'mdi-check' }}
          </v-icon>
        </template>
        {{ !item.editing ? 'Editar' : 'Aplicar' }}
      </v-tooltip>
      <v-tooltip left>
        <template #activator="{ on: onTootip }">
          <v-icon
            :disabled="!item.editing"
            icon
            @click="item.editing ? $refs.list.cancelEditItem(item) : $refs.list.removeItem(item)"
            v-on="onTootip"
          >
            mdi-close
          </v-icon>
        </template>
        Cancelar
      </v-tooltip>
    </template>
  </my-list-card>
</template>
<script>
import MyForm from "@/view/components/MyForm.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MyListCard from "@/view/components/MyListCard.vue";
import MySelect from "@/view/components/MySelect.vue";
import MyCurrency from "@/view/components/MyCurrency.vue";
import MyActionConfirmation from "@/view/components/MyActionConfirmation.vue";
import moment from "moment";

export default {
  name: 'PesquisaAcompanhamentoEtapaExecucaoForm',

  components: {MyActionConfirmation, MyCurrency, MySelect, MyListCard, MyTextArea, MyTextField, MyForm},

  data: () => ({
    headers: [
      { text: 'Descrição', value: 'description' },
      { text: 'Início Previsto', value: 'forecast_start_at', align: 'center', width: 155 },
      { text: 'Término Previstos', value: 'forecast_end_at', align: 'center', width: 155 },
      { text: 'R$ Orçado', value: 'budget_value', width: 175 },
      { text: 'Prazo', value: 'deadline', align: 'center', width: 0 },
      { text: 'Início Real', value: 'started_at', align: 'center', width: 155 },
      { text: 'Término Real', value: 'ended_at', align: 'center', width: 155 },
      { text: 'R$ Realizado', value: 'actual_value', width: 175 },
      { text: 'R$ Orcado x Real.', value: 'saving', width: 120 },
      { text: '', value: 'action', width: 88 },
    ],
    defaultItem: {
      id: 0,
      status: null,
      description: null,
      started_at: null,
      ended_at: null,
      budget: null,
      actual: null,
      editing: true,
    },
  }),

  methods: {
    deadline(item) {
      if (item.ended_at) {
        const diffDays = moment(item.forecast_end_at).diff(moment(item.ended_at), 'days');

        if (diffDays < 0) return { text: 'Atrasado', color: 'red' }
        return { text: 'No prazo', color: 'green' }
      } else {
        const diffDays = moment(item.forecast_end_at).diff(moment(moment().format('YYYY-MM-DD')), 'days');

        if (diffDays < 0) return { text: 'Atrasado', color: 'red' }
        if (diffDays === 0) return { text: 'Hoje', color: 'amber' }
        if (diffDays === 1) return { text: 'Amanhã', color: 'orange' }
        return { text: `${diffDays} dias`, color: 'green' }
      }
    },

    getSavingValue(item) {
      const value =  item.budget_value - item.actual_value;

      if (value < 0) return { value: value * -1, color: 'red' }
      if (value >= 0) return { value: value, color: 'green' }
    },
  },
}
</script>