<template>
  <my-list-card
    :default-item="defaultItem"
    editable
    :headers="headers"
    headerColor="white"
    disable-pagination
    :params="{ research_project_id: $route.params.id }"
    outlined
    ref="list"
    resource="projeto-pesquisa/acompanhamento/etapas-tap"
    :show-add-action="!disabled"
    title="Cronograma de Execução do Projeto"
  >
    <template #item.description="{ item }">
      <my-text-field
        v-if="item.editing"
        v-model="item.description"
        :error-messages="item.errors && item.errors.description ? item.errors.description : []"
      />
      <span v-else>{{ item.description }}</span>
    </template>
    <template #item.forecast_start_at="{ item }">
      <my-text-field
          v-if="item.editing"
          v-model="item.forecast_start_at"
          :error-messages="item.errors && item.errors.forecast_start_at ? item.errors.forecast_start_at : []"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="date"
      />
      <span v-else>{{ item.forecast_start_at | date }}</span>
    </template>
    <template #item.forecast_end_at="{ item }">
      <my-text-field
          v-if="item.editing"
          v-model="item.forecast_end_at"
          :error-messages="item.errors && item.errors.forecast_end_at ? item.errors.forecast_end_at : []"
          mask="##/##/####"
          placeholder="dd/mm/aaaa"
          type="date"
      />
      <span v-else>{{ item.forecast_end_at | date }}</span>
    </template>
    <template #item.budget_value="{ item }">
      <my-currency
          v-if="item.editing"
          v-model="item.budget_value"
          clearable
          :error-messages="item.errors && item.errors.budget_value ? item.errors.budget_value : []"
          prefix="R$"
      />
      <v-row v-else dense no-gutters>
        <v-col cols="auto">
          <span>R$</span>
        </v-col>
        <v-col class="text-right">
          <span>{{ item.budget_value | formatNumber }}</span>
        </v-col>
      </v-row>
    </template>
    <template #item.action="{ item }">
      <v-tooltip left>
        <template #activator="{ on: onTootip }">
          <v-icon
            class="mr-1"
            :disabled="disabled"
            icon
            @click="!item.editing ? $refs.list.editItem(item) : $refs.list.save(item)"
            v-on="onTootip"
          >
            {{ !item.editing ? 'mdi-pencil-outline' : 'mdi-check' }}
          </v-icon>
        </template>
        {{ !item.editing ? 'Editar' : 'Aplicar' }}
      </v-tooltip>
      <v-tooltip v-if="item.editing" left>
        <template #activator="{ on: onTootip }">
          <v-icon
            icon
            @click="item.editing ? $refs.list.cancelEditItem(item) : $refs.list.removeItem(item)"
            v-on="onTootip"
          >
            mdi-close
          </v-icon>
        </template>
        Cancelar
      </v-tooltip>
      <my-action-confirmation
          v-else
          action="delete"
          :disabled="disabled"
          icon="mdi-delete-outline"
          :resource="`projeto-pesquisa/acompanhamento/etapas-tap/${item.id}`"
          tooltip="Excluir"
          @success="$refs.list.removeItem(item)"
      />
    </template>
  </my-list-card>
</template>
<script>
import MyForm from "@/view/components/MyForm.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MyListCard from "@/view/components/MyListCard.vue";
import ApiService from "@/core/services/api.service";
import MySelect from "@/view/components/MySelect.vue";
import MyCurrency from "@/view/components/MyCurrency.vue";
import MyActionConfirmation from "@/view/components/MyActionConfirmation.vue";

export default {
  name: 'PesquisaAcompanhamentoCronogramaExecucaoForm',

  components: {MyActionConfirmation, MyCurrency, MySelect, MyListCard, MyTextArea, MyTextField, MyForm},

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      { text: 'Descrição', value: 'description' },
      { text: 'Início Previsto', value: 'forecast_start_at', align: 'center', width: 155 },
      { text: 'Término Previstos', value: 'forecast_end_at', align: 'center', width: 155 },
      { text: 'R$ Orçado', value: 'budget_value', width: 155 },
      { text: '', value: 'action', width: 84 },
    ],
    defaultItem: {
      id: 0,
      status: null,
      description: null,
      started_at: null,
      ended_at: null,
      budget: null,
      editing: true,
    },
  }),
}
</script>