<template>
  <my-form
    v-model="item"
    title="Termo de Solicitação de Projeto"
    resource="projeto-pesquisa/solicitacao"
    hide-default-actions
    fill-header-with-actions
    expandable
  >
    <v-card-title>
      Dados do Projeto
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <my-text-field
              v-model="item.project_name"
              disabled
              label="Nome"
              required
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Solicitante
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="5">
          <my-text-field
              v-model="item.owner_name"
              disabled
              label="Nome"
              required
          />
        </v-col>
        <v-col cols="12" md="3">
          <my-text-field
              v-model="item.owner_position"
              disabled
              label="Cargo"
              required
          />
        </v-col>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="item.owner_email"
              disabled
              label="E-mail"
              placeholder="email@exemplo.com.br"
              required
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Parceiro
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="item.partner_name"
              disabled
              label="Nome"
          />
        </v-col>
        <v-col cols="12" md="2">
          <my-text-field
              v-model="item.partner_contact"
              disabled
              label="Contato"
          />
        </v-col>
        <v-col cols="12" md="2">
          <my-text-field
              v-model="item.partner_phone"
              disabled
              label="Telefone"
              mask="(##) #####-####"
              placeholder="(00) 00000-0000"
          />
        </v-col>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="item.partner_site"
              disabled
              label="Site"
              placeholder="https://www.exemplo.com.br"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Detalhes do Projeto
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <my-text-area
              v-model="item.tsp.description"
              disabled
              label="Descrição do Produto"
              required
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
              v-model="item.tsp.goal"
              disabled
              label="Objetivos da Avaliação"
              required
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
              v-model="item.tsp.additional_information"
              disabled
              label="Informações Adicionais"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <custom-snackbar ref="snackbar" />
  </my-form>
</template>
<script>
import MyForm from "@/view/components/MyForm.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";

export default {
  name: 'PesquisaAcompanhamentoTspForm',

  components: {MyTextArea, MyTextField, MyForm},

  data: () => ({
    item: {
      project_name: null,
      owner_name: null,
      owner_position: null,
      owner_email: null,
      partner_name: null,
      partner_contact: null,
      partner_phone: null,
      partner_site: null,
      tsp: { // TSP = Termo de Solicitação de Projeto
        description: null,
        goal: null,
        additional_information: null,
      }
    },
  })
}
</script>