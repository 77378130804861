import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-list-card',{ref:"list",attrs:{"default-item":_vm.defaultItem,"editable":"","fill-header":"","headers":_vm.headers,"headerColor":"white","hide-add-action":"","disable-pagination":"","params":{ research_project_id: _vm.$route.params.id },"resource":"projeto-pesquisa/acompanhamento/etapas-exec-tap","title":"Acompanhamento de Execução do Projeto"},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id === 0)?_c('my-text-field',{attrs:{"error-messages":item.errors && item.errors.description ? item.errors.description : []},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.forecast_start_at",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id === 0)?_c('my-text-field',{attrs:{"error-messages":item.errors && item.errors.forecast_start_at ? item.errors.forecast_start_at : [],"mask":"##/##/####","placeholder":"dd/mm/aaaa","type":"date"},model:{value:(item.forecast_start_at),callback:function ($$v) {_vm.$set(item, "forecast_start_at", $$v)},expression:"item.forecast_start_at"}}):_c('span',[_vm._v(_vm._s(_vm._f("date")(item.forecast_start_at)))])]}},{key:"item.forecast_end_at",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id === 0)?_c('my-text-field',{attrs:{"error-messages":item.errors && item.errors.forecast_end_at ? item.errors.forecast_end_at : [],"mask":"##/##/####","placeholder":"dd/mm/aaaa","type":"date"},model:{value:(item.forecast_end_at),callback:function ($$v) {_vm.$set(item, "forecast_end_at", $$v)},expression:"item.forecast_end_at"}}):_c('span',[_vm._v(_vm._s(_vm._f("date")(item.forecast_end_at)))])]}},{key:"item.budget_value",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id === 0)?_c('my-currency',{attrs:{"clearable":"","error-messages":item.errors && item.errors.budget_value ? item.errors.budget_value : [],"prefix":"R$"},model:{value:(item.budget_value),callback:function ($$v) {_vm.$set(item, "budget_value", $$v)},expression:"item.budget_value"}}):_c(VRow,{attrs:{"dense":"","no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',[_vm._v("R$")])]),_c(VCol,{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.budget_value)))])])],1)]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id !== 0)?_c('my-text-field',{attrs:{"error-messages":item.errors && item.errors.started_at ? item.errors.started_at : [],"mask":"##/##/####","placeholder":"dd/mm/aaaa","type":"date"},model:{value:(item.started_at),callback:function ($$v) {_vm.$set(item, "started_at", $$v)},expression:"item.started_at"}}):_c('span',[_vm._v(_vm._s(_vm._f("date")(item.started_at)))])]}},{key:"item.ended_at",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id !== 0)?_c('my-text-field',{attrs:{"error-messages":item.errors && item.errors.ended_at ? item.errors.ended_at : [],"mask":"##/##/####","placeholder":"dd/mm/aaaa","type":"date"},model:{value:(item.ended_at),callback:function ($$v) {_vm.$set(item, "ended_at", $$v)},expression:"item.ended_at"}}):_c('span',[_vm._v(_vm._s(_vm._f("date")(item.ended_at)))])]}},{key:"item.actual_value",fn:function(ref){
var item = ref.item;
return [(item.editing && item.id !== 0)?_c('my-currency',{attrs:{"error-messages":item.errors && item.errors.actual_value ? item.errors.actual_value : [],"prefix":"R$"},model:{value:(item.actual_value),callback:function ($$v) {_vm.$set(item, "actual_value", $$v)},expression:"item.actual_value"}}):(item.actual_value)?_c(VRow,{attrs:{"dense":"","no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',[_vm._v("R$")])]),_c(VCol,{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.actual_value)))])])],1):_vm._e()]}},{key:"item.saving",fn:function(ref){
var item = ref.item;
return [(item.actual_value)?_c(VRow,{attrs:{"dense":"","no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-medium",class:((_vm.getSavingValue(item).color) + "--text")},[_vm._v("R$")])]),_c(VCol,{staticClass:"text-right font-weight-medium",class:((_vm.getSavingValue(item).color) + "--text")},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.getSavingValue(item).value)))])])],1):_vm._e()]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":"","color":_vm.deadline(item).color,"dark":"","small":""}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.deadline(item).text)+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTootip = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){!item.editing ? _vm.$refs.list.editItem(item) : _vm.$refs.list.save(item)}}},onTootip),[_vm._v(" "+_vm._s(!item.editing ? 'mdi-pencil-outline' : 'mdi-check')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(!item.editing ? 'Editar' : 'Aplicar')+" ")]),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTootip = ref.on;
return [_c(VIcon,_vm._g({attrs:{"disabled":!item.editing,"icon":""},on:{"click":function($event){item.editing ? _vm.$refs.list.cancelEditItem(item) : _vm.$refs.list.removeItem(item)}}},onTootip),[_vm._v(" mdi-close ")])]}}],null,true)},[_vm._v(" Cancelar ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }