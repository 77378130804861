<template>
  <my-form
    v-model="form"
    title="Aprovação do Temo de Abertura de Projeto"
    resource="projeto-pesquisa/acompanhamento/aprovar-tap"
    fill-header-with-actions
    :expandable="closed"
    :hide-default-actions="closed"
    @success="$emit('success')"
    @validate="errors = $event"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <my-select
              v-model="form.situation"
              :disabled="closed"
              :error-messages="errors.situation"
              label="Situação"
              :filter="[3, 4]"
              required
              resource="projeto-pesquisa/situacoes"
              return-object
          />
        </v-col>
        <v-col cols="12" md="3">
          <my-text-field
              v-model="form.approve_committee_at"
              :disabled="closed"
              :error-messages="errors.approve_committee_at"
              label="Data do Comitê"
              mask="##/##/####"
              placeholder="dd/mm/aaaa"
              required
              type="date"
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
              v-model="form.approve_justification"
              :disabled="closed"
              :error-messages="errors.approve_justification"
              label="Justificativa"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <custom-snackbar ref="snackbar" />
  </my-form>
</template>
<script>
import MyForm from "@/view/components/MyForm.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MySelect from "@/view/components/MySelect.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";

export default {
  name: 'PesquisaAcompanhamentoAprovacaoTapForm',

  components: {MyTextArea, MySelect, MyTextField, MyForm},

  props: {
    closed: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    form: {
      situation: null,
      approve_committee_at: null,
      approve_justification: null,
    },
    errors: [],
  })
}
</script>